import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import RoomDialog from "./RoomDialog";
import { fetchRoom, editRoom } from "../../actions";

class RoomEdit extends React.PureComponent {
  componentDidMount() {
    if (
      !this.props.room &&
      this.props.roomId &&
      this.props.createdBy &&
      this.props.tokenId
    ) {
      this.props.fetchRoom({
        id: this.props.roomId,
        createdBy: this.props.createdBy,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tokenId !== prevProps.tokenId ||
      prevProps.roomId !== this.props.roomId
    ) {
      this.props.fetchRoom({
        id: this.props.roomId,
        createdBy: this.props.createdBy,
      });
    }
  }

  render() {
    if (!this.props.room) return <div>Waiting.....</div>;
    return (
      <RoomDialog
        room={this.props.room}
        onSaveButtonClick={(room) => this.props.editRoom(room)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search);
  let id = queryParams.roomId;
  const room = state.rooms[id];
  return {
    roomId: queryParams.roomId,
    createdBy: queryParams.createdBy,
    room,
    tokenId: state.auth.userDetails.tokenId,
  };
};

export default connect(mapStateToProps, { fetchRoom, editRoom })(RoomEdit);
