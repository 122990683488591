const actions = {
  signOut: "SIGN_OUT",
  signIn: "SIGN_IN",
  signIn_FB: "SIGN_IN_FB",
  signOut_FB: "SIGN_OUT_FB",
  fetchRooms: "FETCH_ROOMS",
  fetchRoom: "FETCH_ROOM",
  createRoom: "CREATE_ROOM",
  editRoom: "EDIT_ROOM",
  deleteRoom: "DELETE_ROOM",
  addRoomMember: "ADD_ROOM_MEMBER",
  changeMembership: "CHANGE_MEMBERSHIP",
  fetchTrans: "FETCH_TRANSACTIONS",
  addTrans: "ADD_TRANSACTION",
  fetchStats: "FETCH_STATISTICS",
  openDrawer: "OPEN_DRAWER",
  closeDrawer: "CLOSE_DRAWER",
};

export default actions;
