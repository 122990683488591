import actionTypes from "../actions/types";

const drawerReducer = (state = { opened: false }, action) => {
  switch (action.type) {
    case actionTypes.openDrawer:
      return { ...state, opened: true };
    case actionTypes.closeDrawer:
      return { ...state, opened: false };
    default:
      return state;
  }
};

export default drawerReducer;
