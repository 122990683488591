import React from "react";
import { connect } from "react-redux";
import { fetchClosedRooms, fetchStats } from "../../actions";
import RoomGrid from "./RoomGrid";

class RoomList_Closed extends React.PureComponent {
  componentDidMount() {
    if (this.props.auth.userDetails.tokenId) {
      this.props.fetchClosedRooms();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.auth.isSignedIn &&
      this.props.auth.userDetails.tokenId &&
      this.props.auth.userDetails.tokenId !== prevProps.auth.userDetails.tokenId
    ) {
      this.props.fetchClosedRooms();
    }
    if (prevProps.rooms.length !== this.props.rooms.length)
      this.props.rooms.map((room) => {
        return !this.props.stats[room.id] ? this.props.fetchStats(room) : null;
      });
  }

  render() {
    return <RoomGrid rooms={this.props.rooms} stats={this.props.stats} />;
  }
}

const mapStateToProps = (state) => {
  return {
    rooms: Object.values(state.rooms),
    stats: state.stats,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { fetchClosedRooms, fetchStats })(
  RoomList_Closed
);
