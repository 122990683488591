import _ from "lodash";
import actionTypes from "../actions/types";
import defaultState from "./defaultState";

const roomReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.fetchRoom:
    case actionTypes.editRoom:
    case actionTypes.createRoom:
    case actionTypes.changeMembership:
    case actionTypes.addRoomMember:
      return { ...state, [action.payload.id]: action.payload };
    case actionTypes.fetchRooms:
      return { ..._.mapKeys(action.payload.rooms, "id") };
    case actionTypes.signOut:
      return {};
    default:
      return state;
  }
};

export default roomReducer;
