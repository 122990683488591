import actionTypes from "../actions/types";

const statsReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetchStats:
      return { ...state, [action.payload.roomId]: action.payload };
    case actionTypes.signOut:
      return {};
    default:
      return state;
  }
};

export default statsReducer;
