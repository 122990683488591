import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "50%",
    left: "50%"
  }
});

function Spinner(props) {
  const { classes } = props;
  return (
    <div>
      <CircularProgress className={classes.progress} color={props.color} />
    </div>
  );
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

export default withStyles(styles)(Spinner);
