const FACEBOOK = "FACEBOOK";
const GOOGLE = "GOOGLE";
const AUTH_PROVIDER = "AUTH_PROVIDER";

const setPreferedAuthProviderAsGoogle = () => {
  setAuthProvider(GOOGLE);
};

const isGoogleDefault = () => {
  return getAuthProvider() === GOOGLE;
};

const setPreferedAuthProviderAsFaceBook = () => {
  setAuthProvider(FACEBOOK);
};

const isFacebookDefault = () => {
  return getAuthProvider() === FACEBOOK;
};

const setAuthProvider = (provider) => {
  localStorage.setItem(AUTH_PROVIDER, provider);
};

const getAuthProvider = () => {
  return localStorage.getItem(AUTH_PROVIDER);
};

export {
  setPreferedAuthProviderAsGoogle,
  setPreferedAuthProviderAsFaceBook,
  isFacebookDefault,
  isGoogleDefault,
};
