import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Home } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "50%",
    marginTop: "10vmin",
  },
}));

const NotFound = ({ linkToHome }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="p">
          Page not found :(
        </Typography>
        <Typography component="p">
          Maybe the page you are looking for has been removed, or you typed in
          the wrong URL
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="Return to home" aria-label="Return to home">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            href={linkToHome}
            aria-label="Home"
            startIcon={<Home />}
          >
            Home
          </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default NotFound;
