import actionTypes from "./types";
import roomsApi from "../data/rooms";
import transApi from "../data/transactions";

export const fetchRooms = () => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get("/myactiverooms");
    dispatch({ type: actionTypes.fetchRooms, payload: response.data });
  };
};

export const fetchAllRooms = () => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get("/myrooms");
    dispatch({ type: actionTypes.fetchRooms, payload: response.data });
  };
};

export const fetchCreatedRooms = () => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get("/mycreatedrooms");
    dispatch({ type: actionTypes.fetchRooms, payload: response.data });
  };
};

export const fetchClosedRooms = () => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get("/myclosedrooms");
    dispatch({ type: actionTypes.fetchRooms, payload: response.data });
  };
};

export const fetchRoom = ({ id, createdBy }) => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get(`/${createdBy}/${id}`);
    dispatch({ type: actionTypes.fetchRoom, payload: response.data });
  };
};

export const editRoom = (room) => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).patch(`/${room.createdBy}/${room.id}`, room);
    dispatch({ type: actionTypes.editRoom, payload: response.data });
  };
};

export const createRoom = (room) => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).post("", room);
    dispatch({ type: actionTypes.createRoom, payload: response.data });
  };
};

export const changeMembership = (room, email, isDisabled) => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).patch(`/${room.createdBy}/${room.id}/member`, { email, isDisabled });
    dispatch({ type: actionTypes.changeMembership, payload: response.data });
  };
};

export const addRoomMember = (room, email) => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).post(`/${room.createdBy}/${room.id}/member`, {
      email,
    });
    dispatch({ type: actionTypes.addRoomMember, payload: response.data });
  };
};

export const fetchTrans = ({ roomId, createdBy }) => {
  return async (dispatch, getState) => {
    const response = await transApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get(`${createdBy}/${roomId}/trans`);
    dispatch({
      type: actionTypes.fetchTrans,
      payload: { [roomId]: response.data.transactions },
    });
  };
};

export const addTrans = (newTransaction, room) => {
  return async (dispatch, getState) => {
    const response = await transApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).post(`/${room.createdBy}/${room.id}/trans`, newTransaction);
    dispatch({ type: actionTypes.addTrans, payload: response.data });
  };
};

export const fetchStats = (room) => {
  return async (dispatch, getState) => {
    const response = await roomsApi(
      getState().auth.userDetails.tokenId,
      getState().auth.provider
    ).get(`/${room.createdBy}/${room.id}/settlements`);
    dispatch({ type: actionTypes.fetchStats, payload: response.data });
  };
};

export const signIn = (id, email, name, imageUrl, tokenId) => {
  return {
    type: actionTypes.signIn,
    payload: { id, email, name, imageUrl, tokenId },
  };
};

export const signInFB = (id, email, name, imageUrl, tokenId) => {
  return {
    type: actionTypes.signIn_FB,
    payload: { id, email, name, imageUrl, tokenId },
  };
};
export const signOutFB = () => {
  return { type: actionTypes.signOut_FB };
};

export const signOut = () => {
  return { type: actionTypes.signOut };
};

export const openDrawer = () => {
  return { type: actionTypes.openDrawer };
};

export const closeDrawer = () => {
  return { type: actionTypes.closeDrawer };
};
