import React from "react";
//import FacebookLogin from "react-facebook-login";
import { connect } from "react-redux";
//import Avatar from "@material-ui/core/Avatar";
import { signInFB, signOutFB } from "../../actions";
//import { authProviders } from "../../data/const";
//import { Tooltip } from "@material-ui/core";

class FacebookAuth extends React.Component {
  componentDidUpdate() {
    this.auth = window.FB;
  }
  onSignInButtonClick = (curUser) => {
    if (
      curUser !== null &&
      curUser.status !== "unknown" &&
      curUser.accessToken !== ""
    ) {
      this.props.signInFB(
        curUser.id,
        curUser.email,
        curUser.name,
        curUser.picture.data.url,
        curUser.accessToken
      );
      if (typeof this.props.PostLogin === "function") {
        this.props.PostLogin();
      }
    }
  };

  onSignOutButtonClick = () => {
    window.FB.logout(() => {
      this.props.signOutFB();
    });
  };

  renderAuthButton() {
    <div>Not supported.</div>;
    // if (
    //   this.props.auth.isSignedIn === null ||
    //   (this.props.auth.provider !== undefined &&
    //     this.props.auth.provider !== authProviders.facebook)
    // )
    //   return <div />;
    // else if (this.props.auth.isSignedIn)
    //   return (
    //     <Tooltip title="Logout" aria-label="Logout">
    //       <Avatar
    //         alt={this.props.auth.userDetails.name}
    //         src={this.props.auth.userDetails.imageUrl}
    //         onClick={this.onSignOutButtonClick}
    //       />
    //     </Tooltip>
    //   );
    // else
    //   return (
    //     <FacebookLogin
    //       appId="2778450502410628"
    //       size="small"
    //       autoLoad={this.props.AutoLoad}
    //       fields={"name,email,picture,birthday"}
    //       callback={this.onSignInButtonClick}
    //       icon="fa-facebook"
    //     />
    //   );
  }

  render() {
    return <div>{this.renderAuthButton()}</div>;
  }
}
const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { signInFB, signOutFB })(FacebookAuth);
