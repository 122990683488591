import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import history from "../../history";

import ListPage from "../general/ListPage";
import AddButton from "../general/AddButton";

import { fetchTrans } from "../../actions/index";

class TransactionList extends React.PureComponent {
  state = {};
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({ query, tokenId: this.props.tokenId });
    if (this.props.roomId && this.props.createdBy && this.props.tokenId) {
      this.props.fetchTrans({
        roomId: this.props.roomId,
        createdBy: this.props.createdBy,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tokenId !== this.props.tokenId) {
      this.props.fetchTrans({
        roomId: this.props.roomId,
        createdBy: this.props.createdBy,
      });
    }
  }

  getListPageTitles() {
    return [
      { label: "Date", attribute: "dateTime", align: "inherit", style: "date" },
      { label: "Owner", attribute: "owner", align: "inherit" },
      { label: "Description", attribute: "description", align: "inherit" },
      { label: "Amount", attribute: "amount", align: "right" },
    ];
  }

  getListPageRows() {
    return this.props.trans ? this.props.trans : [];
  }

  handleAddTransactionClick = () => {
    history.push(
      `/transactions/new?roomId=${this.props.roomId}&createdBy=${this.props.createdBy}`
    );
  };

  render() {
    if (this.state.query) {
      return (
        <>
          <ListPage
            titles={this.getListPageTitles()}
            rows={this.getListPageRows()}
          />
          <AddButton onButtonClick={this.handleAddTransactionClick} />
        </>
      );
    } else return <div>Loading</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = queryString.parse(ownProps.location.search);
  const trans = state.trans[query.roomId] ? state.trans[query.roomId] : [];
  return {
    trans,
    roomId: query.roomId,
    createdBy: query.createdBy,
    tokenId: state.auth.userDetails.tokenId,
  };
};

export default connect(mapStateToProps, { fetchTrans })(TransactionList);
