import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

import { Form, Field } from "react-final-form";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = {
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
};

class TransactionForm extends React.PureComponent {
  state = {
    open: true,
  };

  handleCloseCancel = () => {
    this.props.onCancel();
  };

  handleCloseOK = () => {
    this.props.onSubmit();
  };

  renderBasicTextField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    return (
      <TextField
        error={touched && error !== undefined}
        required
        autoComplete="off"
        margin="dense"
        label={touched && error !== undefined ? `${label}( ${error} )` : label}
        type={custom.type}
        fullWidth
        {...input}
        {...custom}
      />
    );
  };

  render() {
    const { classes, room } = this.props;
    return (
      <Form
        validate={validate}
        onSubmit={this.props.onSubmit}
        render={({ handleSubmit, pristine, reset, submitting }) => (
          <Dialog
            fullScreen
            open={this.state.open}
            onClose={this.handleCloseCancel}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={this.handleCloseCancel}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  {`New transaction for: ${room.name}`}
                </Typography>
                <Button color="inherit" onClick={handleSubmit}>
                  save
                </Button>
              </Toolbar>
            </AppBar>
            <List>
              <ListItem>
                <Field
                  name="description"
                  component={this.renderBasicTextField}
                  label="Description"
                  type="text"
                />
              </ListItem>
              <Divider />
              <ListItem>
                <Field
                  name="amount"
                  component={this.renderBasicTextField}
                  label="Amount"
                  type="number"
                />
              </ListItem>
            </List>
          </Dialog>
        )}
      />
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = ["description", "amount"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (Number(values["amount"]) <= 0) {
    errors["amount"] = "Only greater than zero.";
  }
  return errors;
};

export default withStyles(styles)(TransactionForm);
