import { combineReducers } from "redux";
import roomReducer from "./roomReducer";
import transReducer from "./transReducer";
import statsReducer from "./statsReducer";
import authReducer from "./authReducer";
import drawerReducer from "./drawerReducer";

export default combineReducers({
  drawer: drawerReducer,
  rooms: roomReducer,
  trans: transReducer,
  stats: statsReducer,
  auth: authReducer,
});
