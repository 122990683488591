import React from "react";
import { connect } from "react-redux";
import RoomDialog from "./RoomDialog";
import { createRoom } from "../../actions";

class RoomCreate extends React.Component {
  render() {
    return (
      <RoomDialog
        room={{ name: "", description: "" }}
        onSaveButtonClick={(room) => this.props.createRoom(room)}
      />
    );
  }
}

export default connect(null, { createRoom })(RoomCreate);
