import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import history from "../../history";
import TransactionFrom from "./TransactionForm";
import Spinner from "../general/Spinner";
import { addTrans, fetchRoom } from "../../actions";

class TransactionCreate extends React.PureComponent {
  handleFormSubmit = (formValues) => {
    this.props.addTrans(
      { ...formValues, amount: Number(formValues.amount) },
      this.props.room
    );
    this.navigateToRoomTrans();
  };

  navigateToRoomTrans = () => {
    history.push(
      `/transactions?roomId=${this.props.room.id}&createdBy=${this.props.room.createdBy}`
    );
  };

  componentDidMount() {
    this.roomID = this.props.roomID;
    if (this.props.roomID && !this.props.room)
      this.props.fetchRoom(this.props.roomID);
  }

  render() {
    if (!this.props.room) {
      return <Spinner color="primary" />;
    }
    return (
      <TransactionFrom
        onSubmit={this.handleFormSubmit}
        onCancel={this.navigateToRoomTrans}
        room={this.props.room}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = queryString.parse(ownProps.location.search);
  var roomID = query.roomId;
  return {
    roomID,
    room: state.rooms[roomID],
  };
};

export default connect(mapStateToProps, { addTrans, fetchRoom })(
  TransactionCreate
);
