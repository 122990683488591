import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

const renderTableCell = (title, row, inx, props) => {
  switch (title.style) {
    case "switch":
      return (
        <TableCell key={inx} align={`${title.align}`}>
          <Switch
            checked={row[title.attribute]}
            onChange={(event, checked) => {
              props[`${title.onClick}`](event, checked, row);
            }}
          />
        </TableCell>
      );
    case "date":
      var date = new Date(row[`${title.attribute}`]);
      return (
        <TableCell key={inx} align={`${title.align}`}>
          {date.toLocaleTimeString()} <br /> {date.toLocaleDateString()}
        </TableCell>
      );
    default:
      return (
        <TableCell key={inx} align={`${title.align}`}>
          {row[`${title.attribute}`]}
        </TableCell>
      );
  }
};

class ListPage extends React.Component {
  render() {
    const { classes, rows, titles } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {titles.map((title, index) => {
                return (
                  <TableCell key={index} align={`${title.align}`}>
                    {title.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  {titles.map((title, inx) => {
                    return renderTableCell(title, row, inx, this.props);
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
ListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListPage);
