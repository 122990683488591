import React from "react";
import { connect } from "react-redux";
import GoogleAuth from "./GoogleAuth";
import { signIn } from "../../actions";
//import FacebookAuth from "./FacebookAuth";
import createHashHistory from "../../history";
import {
  withStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import {
  setPreferedAuthProviderAsGoogle,
  setPreferedAuthProviderAsFaceBook,
  //isFacebookDefault,
} from "../../hoc/storage";
import { CssBaseline } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    paddingTop: "10%",
  },
  auth: {
    display: "flex",
    justifyContent: "center",
  },
});
const themeDark = createTheme({
  palette: {
    background: {
      default: "#cce6ff",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

class Home extends React.PureComponent {
  facebookPostLogin = () => {
    setPreferedAuthProviderAsFaceBook();
    createHashHistory.push("/home");
  };

  googlePostLogin = () => {
    setPreferedAuthProviderAsGoogle();
    createHashHistory.push("/home");
  };

  componentDidMount() {}
  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={themeDark}>
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <div className={classes.auth}>
              <GoogleAuth PostLogin={this.googlePostLogin} />
            </div>
            {/* <div className={classes.auth}>
              <FacebookAuth
                AutoLoad={isFacebookDefault()}
                PostLogin={this.facebookPostLogin}
              />
            </div> */}
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, { signIn })(withStyles(styles)(Home));
