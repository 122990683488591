import actionTypes from "../actions/types";

const transReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetchTrans:
      const payload = action.payload;
      return { ...state, ...payload };
    case actionTypes.addTrans:
      var existingTrans = state[action.payload.roomId];
      return {
        ...state,
        [action.payload.roomId]: [...existingTrans, action.payload],
      };
    case actionTypes.signOut:
      return {};
    default:
      return state;
  }
};

export default transReducer;
