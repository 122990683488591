import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import googleSignInPNG from "../../assets/btn_google_signin_light_normal_web.png";
import { signIn, signOut } from "../../actions";
import { authProviders } from "../../data/const";
import { Avatar, Tooltip } from "@material-ui/core";

const styles = (theme) => ({
  auth: {
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
});

class GooleAuth extends React.Component {
  componentDidMount() {
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          clientId:
            "936338170154-pt1qddeq02hv8pcs1m6nov3c4cmr81jq.apps.googleusercontent.com",
          scope: "email",
        })
        .then(() => {
          this.auth = window.gapi.auth2.getAuthInstance();
          this.onAuthChanged(this.auth.isSignedIn.get());
          this.auth.isSignedIn.listen(this.onAuthChanged);
        });
    });
  }

  onAuthChanged = (isSignedIn) => {
    if (isSignedIn) {
      let currentUser = this.auth.currentUser.get();
      this.props.signIn(
        currentUser.getId(),
        currentUser.getBasicProfile().getEmail(),
        currentUser.getBasicProfile().getName(),
        currentUser.getBasicProfile().getImageUrl(),
        currentUser.getAuthResponse().id_token
      );
      if (typeof this.props.PostLogin === "function") {
        this.props.PostLogin();
      }
    } else {
      this.props.signOut();
    }
  };

  onSignInButtonClick = () => {
    if (this.auth) this.auth.signIn();
  };

  onSignOutButtonClick = () => {
    this.auth.signOut();
  };

  renderAuthButton() {
    if (
      this.props.auth.isSignedIn === null ||
      (this.props.auth.provider !== undefined &&
        this.props.auth.provider !== authProviders.google)
    )
      return <div />;
    else if (this.props.auth.isSignedIn)
      return (
        <Tooltip title="Logout" aria-label="Logout">
          <Avatar
            className={this.props.classes.auth}
            alt={this.props.auth.userDetails.name}
            src={this.props.auth.userDetails.imageUrl}
            onClick={this.onSignOutButtonClick}
          />
        </Tooltip>
      );
    else
      return (
        <img
          src={googleSignInPNG}
          onClick={this.onSignInButtonClick}
          alt="Log in with Google!"
        />
      );
  }

  render() {
    return <div>{this.renderAuthButton()}</div>;
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { signIn, signOut })(
  withStyles(styles)(GooleAuth)
);
