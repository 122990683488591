import React from "react";
import { MenuItem, Link } from "@material-ui/core";

export default function SMenuItem({ label, linkTo, onClick }) {
  return (
    <Link href={linkTo} color="inherit" underline="none">
      <MenuItem onClick={onClick}>{label}</MenuItem>
    </Link>
  );
}
