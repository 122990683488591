import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  email: {
    display: "flex",
    justifyContent: "center",
  },
  name: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  },
});

export default function UserName({ name, email }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.name}> {name} </div>
      <div className={classes.email}>{email}</div>
    </React.Fragment>
  );
}
