import axios from "axios";
import { config } from "./const";

var roomsApi = (tokenId, authProvider) => {
  return axios.create({
    baseURL: `${config.url}/api/room`,
    headers: { Authorization: `Bearer ${tokenId}`, 'Auth-Provider': authProvider },
  });
};

export default roomsApi;
