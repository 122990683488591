import actionTypes from "../actions/types";

const INITIAL_STATE = {
  isSignedIn: undefined,
  userDetails: {
    email: undefined,
  },
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.signIn:
      return {
        ...state,
        isSignedIn: true,
        provider: "Google",
        userDetails: action.payload,
      };
    case actionTypes.signOut:
      if (!state.provider || state.provider === "Google")
        return {
          ...state,
          isSignedIn: false,
          userDetails: {},
          provider: undefined,
        };
      else return state;

    case actionTypes.signIn_FB:
      return {
        ...state,
        isSignedIn: true,
        provider: "Facebook",
        userDetails: action.payload,
      };
    case actionTypes.signOut_FB:
      if (!state.provider || state.provider === "Facebook")
        return {
          ...state,
          isSignedIn: false,
          userDetails: {},
          provider: undefined,
        };
      else return state;
    default:
      return state;
  }
};

export default authReducer;
