import React from "react";
import { Divider, Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import AccountDetails from "./AccountDetails";
import { closeDrawer } from "../../actions";
import SMenuItem from "./SMenuItem";

class Menu extends React.PureComponent {
  render() {
    return (
      <Drawer
        anchor="left"
        open={this.props.open}
        onClose={this.props.closeDrawer}
        variant="temporary"
      >
        <AccountDetails />
        <Divider />
        <SMenuItem label="Home" linkTo="/#/home" onClick={this.props.closeDrawer} />
        <SMenuItem label="All rooms" linkTo="/#/allrooms" onClick={this.props.closeDrawer} />
        <SMenuItem label="My rooms" linkTo="/#/myrooms" onClick={this.props.closeDrawer} />
        <SMenuItem label="Closed rooms" linkTo="/#/closed" onClick={this.props.closeDrawer} />
      </Drawer>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return { open: state.drawer.opened };
};

export default connect(mapStateToProps, { closeDrawer })(Menu);
