import React from "react";
import { connect } from "react-redux";
import UserName from "./UserName";
import FacebookAuth from "../general/FacebookAuth";
import GoogleAuth from "../general/GoogleAuth";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    marginTop: "1vmin",
    marginLeft: "5vmin",
    marginRight: "5vmin",
    fontStyle: "italic",
    color: "#A9A9A9",
  },
});

class AccountDetails extends React.PureComponent {
  render() {
    return (
      <div className={this.props.classes.root}>
        <GoogleAuth />
        <FacebookAuth />
        <UserName
          name={this.props.userDetails.name}
          email={this.props.userDetails.email}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { userDetails: state.auth.userDetails };
};

export default connect(mapStateToProps, {})(withStyles(styles)(AccountDetails));
