import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class DialogForm extends React.PureComponent {
  state = { textFieldValue: "" };

  handleKeyPressEvent = event => {
    if (event.key === "Enter") {
      this.handleOKClick();
    }
  };

  handleTextFieldChange = event => {
    this.setState({ textFieldValue: event.target.value });
  };

  handleOKClick = () => {
    this.props.onOKClick(this.state.textFieldValue);
    this.setState({ textFieldValue: "" });
  };

  handleCancelClick = () => {
    this.setState({ textFieldValue: "" });
    this.props.onCancelClick();
  };

  render() {
    return (
      <Dialog
        open={this.props.visible}
        aria-labelledby="form-dialog-title"
        onKeyPress={this.handleKeyPressEvent}
        onEscapeKeyDown={this.props.onCancelClick}>
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.context}</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            required
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            onChange={this.handleTextFieldChange}
            value={this.state.textFieldValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelClick} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleOKClick} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogForm;
