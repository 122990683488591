import React from "react";
import queryString from "query-string";

import { connect } from "react-redux";
import { fetchRoom, changeMembership, addRoomMember } from "../../actions";

import ListPage from "../general/ListPage";
import DialogForm from "../general/DialogForm";
import AddButton from "../general/AddButton";

class MemberList extends React.PureComponent {
  state = { addDialogVisible: false };
  componentDidMount() {
    if (
      !this.props.room &&
      this.props.roomId &&
      this.props.createdBy &&
      this.props.tokenId
    ) {
      this.props.fetchRoom({
        id: this.props.roomId,
        createdBy: this.props.createdBy,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tokenId !== this.props.tokenId ||
      prevProps.roomId !== this.props.roomId
    ) {
      this.props.fetchRoom({
        id: this.props.roomId,
        createdBy: this.props.createdBy,
      });
    }
  }

  handleMemberhipChange = (event, checked, member) => {
    this.props.changeMembership(this.props.room, member.email, checked);
  };

  getListPageTitles() {
    return [
      { label: "Email", attribute: "email", align: "inherit" },
      {
        label: "Disabled",
        attribute: "isDisabled",
        align: "inherit",
        style: "switch",
        onClick: "handleMemberhipChange",
      },
    ];
  }

  getListPageRows() {
    return this.props.room && this.props.room.members != null
      ? this.props.room.members
      : [];
  }

  changeAddMemberVisibility = (isVisible) => {
    this.setState({ addDialogVisible: isVisible });
  };

  handleAddMemberOkClick = (newEmail) => {
    this.changeAddMemberVisibility(false);
    this.props.addRoomMember(this.props.room, newEmail);
  };

  render() {
    return (
      <>
        <ListPage
          titles={this.getListPageTitles()}
          rows={this.getListPageRows()}
          handleMemberhipChange={this.handleMemberhipChange}
        />
        <AddButton
          onButtonClick={() => {
            this.changeAddMemberVisibility(true);
          }}
        />
        <DialogForm
          visible={this.state.addDialogVisible}
          title="Add member"
          context="Enter the email address of the new member. New members will be able to enter transactions and reconcile the room."
          onCancelClick={() => {
            this.changeAddMemberVisibility(false);
          }}
          onOKClick={this.handleAddMemberOkClick}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search);
  return {
    roomId: queryParams.roomId,
    createdBy: queryParams.createdBy,
    room: state.rooms[queryParams.roomId],
    tokenId: state.auth.userDetails.tokenId,
  };
};

export default connect(mapStateToProps, {
  fetchRoom,
  changeMembership,
  addRoomMember,
})(MemberList);
