import React from "react";
import { connect } from "react-redux";

class SettlementList extends React.PureComponent {
  componentDidMount() {
  }

  componentDidUpdate(props) {
  }

  render() {
    return <div>Wecome to settlements. {this.props.roomid}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roomid: ownProps.match.params.roomid,
    tokenid: state.auth.userDetails.tokenId
  };
};

export default connect(
  mapStateToProps,
  {  }
)(SettlementList);
