import React from "react";
import { Grid } from "@material-ui/core";
import RoomCard from "./RoomCard";
import AddButton from "../general/AddButton";
import history from "../../history";

class RoomGrid extends React.PureComponent {
  onCardClick = (room) => {
    history.push(`/room/?roomId=${room.id}&createdBy=${room.createdBy}`);
  };

  handleAddButtonClick = () => {
    history.push(`/rooms/new`);
  };

  handleMembersButtonClick = (room) => {
    history.push(`/members?roomId=${room.id}&createdBy=${room.createdBy}`);
  };

  handleTransactionsButtonClick = (room) => {
    history.push(`/transactions?roomId=${room.id}&createdBy=${room.createdBy}`);
  };

  renderRooms() {
    if (!this.props.rooms) return <div>Nothing to see here</div>;
    return this.props.rooms.map((room) => {
      return (
        <Grid key={room.id} item md={3}>
          <RoomCard
            room={room}
            onCardClick={this.onCardClick}
            onMembersButtonClick={this.handleMembersButtonClick}
            onTransactionsButtonClick={this.handleTransactionsButtonClick}
            stats={this.props.stats[room.id]}
          />
        </Grid>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          {this.renderRooms()}
        </Grid>
        <AddButton
          onButtonClick={this.handleAddButtonClick}
          tooltip="New room"
        />
      </React.Fragment>
    );
  }
}

export default RoomGrid;
