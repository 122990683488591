import React from "react";
import { Route, Switch, Router } from "react-router-dom";

import RoomList from "./room/RoomList";
import RoomCreate from "./room/RoomCreate";
import RoomEdit from "./room/RoomEdit";
import Header from "./Header";
import TransactionList from "./transaction/TransactionList";
import MemberList from "./member/MemberList";
import TransactionCreate from "./transaction/TransactionCreate";
import SettlementList from "./settlement/SettlementList";

import history from "../history";
import NotFound from "./general/NotFound";
import Home from "./general/Home";
import Menu from "./menu/Menu";
import RoomList_All from "./room/RoomList_All";
import RoomList_Created from "./room/RoomList_Created";
import RoomList_Closed from "./room/RoomList_Closed";

const App = () => {
  return (
    <Router history={history}>
      <>
        <Header />
        <Menu />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={RoomList} />
          <Route path="/allrooms" exact component={RoomList_All} />
          <Route path="/myrooms" exact component={RoomList_Created} />
          <Route path="/closed" exact component={RoomList_Closed} />
          <Route path="/rooms/new" exact component={RoomCreate} />
          <Route path="/room" component={RoomEdit} />
          <Route path="/settlements/:roomid" exact component={SettlementList} />
          <Route path="/transactions/new" component={TransactionCreate} />
          <Route path="/transactions" exact component={TransactionList} />
          <Route path="/members" component={MemberList} />
          <Route path="*" render={() => <NotFound linkToHome="#/home" />} />
        </Switch>
      </>
    </Router>
  );
};
export default App;
