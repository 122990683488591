import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

class AddButton extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Fab
          title={this.props.tooltip}
          color="primary"
          aria-label="New"
          className={classes.fab}
          onClick={this.props.onButtonClick}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

AddButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddButton);
