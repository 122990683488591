import React from "react";
import MainAppBar from "../components/general/MainAppBar";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Header extends React.Component {

  render() {
    if (this.props.auth.isSignedIn) {
      return <MainAppBar />;
    } else {
      return <Redirect to="/#" />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    everything: state,
    requestedUrl: ownProps.location,
  };
};

export default connect(mapStateToProps,{})(Header);
