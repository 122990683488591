import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import history from "../../history";

const styles = {
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RoomDialog extends React.Component {
  state = {
    open: true,
    roomName: this.props.room.name,
    roomDescription: this.props.room.description
  };

  handleClose = () => {
    this.setState({ open: false });
    history.push("/home");
  };

  handleSaveAndClose = () => {
    this.props.onSaveButtonClick({
      ...this.props.room,
      name: this.state.roomName,
      description: this.state.roomDescription
    });
    this.handleClose();
  };

  renderMembers(room) {
    if (!room.members) return;
    return room.members.map(member => {
      return (
        <React.Fragment key={member.email}>
          <ListItem>
            <ListItemText primary={member.email} />
          </ListItem>
          <Divider />
        </React.Fragment>
      );
    });
  }

  hanldeTextFieldChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, room } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {room.name}
              </Typography>
              <Button color="inherit" onClick={this.handleSaveAndClose}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <TextField
                autoComplete="off"
                autoFocus
                margin="dense"
                id="name"
                label="Room name"
                type="text"
                fullWidth
                InputProps={{
                  value: this.state.roomName,
                  onChange: this.hanldeTextFieldChange("roomName")
                }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <TextField
                autoComplete="off"
                margin="dense"
                id="name"
                label="Room description"
                type="text"
                fullWidth
                multiline
                InputProps={{
                  value: this.state.roomDescription,
                  onChange: this.hanldeTextFieldChange("roomDescription")
                }}
              />
            </ListItem>
            <Divider />
            <List>{this.renderMembers(room)}</List>
          </List>
        </Dialog>
      </div>
    );
  }
}

RoomDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired
};

export default withStyles(styles)(RoomDialog);
