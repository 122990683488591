import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import lightGreen from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";

const styles = {
  card: {
    minWidth: 275
  },
  cardContent: {
    cursor: "pointer",
    textAlign: "justify"
  },
  title: {
    fontSize: 18,
    display: "inline-block"
  },
  description: {
    fontisize: 10
  },
  position: {
    fontSize: 36,
    display: "inline-block",
    marginRight: 12,
    color: lightGreen["A400"]
  },
  positionNegative: {
    fontSize: 36,
    display: "inline-block",
    marginRight: 12,
    color: red["A700"]
  }
};

function renderPosition(position) {
  if (position >= 0) return `+${position}`;
  return position;
}

function RoomCard(props) {
  const { classes, room, stats = { position: 0 } } = props;
  return (
    <Card className={classes.card}>
      <CardContent
        className={classes.cardContent}
        onClick={() => {
          props.onCardClick(props.room);
        }}
      >
        <Typography
          component="p"
          className={
            stats.position < 0 ? classes.positionNegative : classes.position
          }
        >
          {renderPosition(stats.position)}
        </Typography>
        <Typography variant="h5" component="h2" className={classes.title}>
          {room.name}
        </Typography>
        <Typography component="p" className={classes.description}>
          {room.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => props.onTransactionsButtonClick(room)}
        >
          Transactions ({stats.roomStatistics ? stats.roomStatistics.totalTransactions : 0})
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => props.onMembersButtonClick(room)}
        >
          Members ({room.members ? room.members.length : 0})
        </Button>
      </CardActions>
    </Card>
  );
}

RoomCard.propTypes = {
  classes: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired
};

export default withStyles(styles)(RoomCard);
